import React, { useEffect, useState } from 'react';
import { RealEstateCard } from '../common/realEstateCard/realEstateCard';
import { PropertyBuildingDocument } from '../../common/typings';
import { ALL_LOCATIONS, ISTRIA, KVARNER } from '../../common/constants';
import { GetHouses, GetHousesByDestination } from '../../service/propertyBuilding';
import { Footer } from '../common/footer/footer';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export const PropertyBuildingsView = () => {
	const { t } = useTranslation();
	const [destination, setDestination] = useState<string>('');
	const [housesData, setHousesData] = useState<PropertyBuildingDocument[] | undefined>(undefined);

	useEffect(() => {
		function getHouses() {
			GetHouses().then(payload => setHousesData(payload));
		}

		function getHousesByDestination() {
			GetHousesByDestination(destination).then(payload => setHousesData(payload));
		}

		destination === ALL_LOCATIONS ? getHouses() : getHousesByDestination();
	}, [destination]);

	return (
		<>
			<section id="buy-house">
				<div className="search">
					<label>{t('destinations.choose_location')}</label>
					<Select className="select" defaultValue={destination} onChange={value => setDestination(value)}>
						<Option value={ALL_LOCATIONS}>{t("destinations.all_locations")}</Option>
						<Option value={ISTRIA}>{t("destinations.ISTRIA")}</Option>
						<Option value={KVARNER}>{t("destinations.KVARNER")}</Option>
					</Select>
				</div>
				{housesData && housesData.length !== 0 && (
					<>
						<h1>{resolveDestination(destination)}</h1>
						<div className="grid-3">
							{housesData.map((house: PropertyBuildingDocument) => (
								<RealEstateCard
									key={Math.random()}
									id={house.id}
									location={house.location}
									cover={house.cover}
									loading={false}
									rooms={house.rooms}
									bathrooms={house.bathrooms}
									price={house.price}
									squareMetres={house.squareMetres}
									houseSquareMetres={house.houseSquareMetres}
									isBought={house.isBought || false}
									isReserved={house.isReserved || false}
									isNewListing={house.isNewListing || false}
								/>
							))}
						</div>
					</>
				)}
				{housesData?.length === 0 && (
					<p className="no-data">{t('no-data')}</p>
				)}
			</section>
			<Footer />
		</>
	);

	function resolveDestination(destination: string) {
		if (destination === ALL_LOCATIONS)
			return t("destinations.all_locations")
		else if (destination === ISTRIA)
			return t("destinations.ISTRIA")
		else
			return t("destinations.KVARNER")
	}
};
