export const RENTAL_COLLECTION: string = "/rental/rental";

export const PURCHASE_COLLECTION: string = "/purchase/";

export const HOUSES_COLLECTION: string = "houses/"

export const PLOTS_COLLECTION: string = "plots/"

export const CONDOS_COLLECTION: string = "condos/"

export const hr_HR = "hr_HR";

export const de_DE = "de_DE";

export const IS_FEATURED = "isFeatured";

export const IS_READY = "isReady";

export const DESTINATION = "destination";

export const EQUALS = "==";

export const ALL_LOCATIONS = "";

export const ISTRIA = "ISTRIA"

export const KVARNER = "KVARNER"

export const CONTINENTAL = "CONTINENTAL"

export const DALMATIA = "DALMATIA"

export const BULLETIN = "■"

export const SERVICE_ID = "service_gy57ei5"

export const CONTACT_TEMPLATE_ID = "template_yc7n8r9"

export const BOOKING_TEMPLATE_ID = "template_5gfgkhr"

export const USER_ID = "user_VFzX8PbXZxsH4M69MczE8"
